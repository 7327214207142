import { notDesktop, styled, Text, useMutation } from "@obvio/app";
import { FadeIn } from "@obvio/template";
import {
  CheckboxInput,
  Form,
  FormPage,
  Grid,
  Stack,
  Submit,
  SubmitState,
  TextInput,
} from "@obvio/ui";
import { useCallback } from "react";

import type { ReactElement } from "react";

const FormWrap = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 1rem;
  button {
    position: absolute;
    right: 0;
  }
`;

const GridWrap = styled(Grid)`
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Text)`
  width: 60%;
  @media ${notDesktop} {
    width: 100%;
  }
`;

export function FormNewsletter(): ReactElement {
  const [requestNewsletter] = useMutation("registerNewsletter");

  const handleSubmit = useCallback(
    (data: { mail: string }) => {
      void requestNewsletter({
        email: data.mail,
      });

      return SubmitState.OK;
    },
    [requestNewsletter],
  );

  return (
    <Stack kind="vertical" spacing="big">
      <Title tag="h2">
        OTRZYMUJ WESELNE PORADY I INSPIRACJE PROSTO NA SWOJĄ SKRZYNKĘ MAILOWĄ!
      </Title>

      <GridWrap templateColumns="1.25fr 1fr" gap="extraLarge">
        <Form contextOnly onSubmit={handleSubmit}>
          <FormPage>
            <FormWrap>
              <TextInput name="mail" label="Adres e-mail" />
              <Submit>Zapisz mnie</Submit>
            </FormWrap>
            <CheckboxInput
              required
              name="rodo"
              label="Wyrażam zgodę na przetwarzanie danych osobowych i kontakt za pośrednictwem poczty elektronicznej. Sprawdź naszą Politykę Prywatności aby dowiedzieć się w jaki sposób przetwarzamy dane. W dowolnym momencie możesz wycofać zgody."
            />
          </FormPage>
          <FormPage successPage>
            <Center>
              <FadeIn>
                <Text as="h3">Dziękujemy</Text>
              </FadeIn>
            </Center>
          </FormPage>
        </Form>

        <TextWrap>
          <Text>
            Bez spamu, tylko wartościowe informacje, raz w miesiącu na twoją
            skrzynkę mailową:
          </Text>
          <ul>
            <Text tag="li">
              Zestawienia najlepszych dostawców i partnerów, od fotografów po
              wedding plannerów.
            </Text>

            <Text tag="li">
              Sezonowe wskazówki dotyczące wyboru menu, atrakcji oraz dodatków
              jak wellness czy atrakcje w plenerze.
            </Text>

            <Text tag="li">
              Najnowsze trendy i pomysły dekoracyjne idealne dla wesela w
              Szklarni.
            </Text>
          </ul>
        </TextWrap>
      </GridWrap>
    </Stack>
  );
}
